import React, { useEffect, useRef, useState } from 'react';
import './OrbitingCircles.css';

// Import numbered SEO images
import seo1 from '../../media/seo (1).png';
import seo2 from '../../media/seo (2).png';
import seo3 from '../../media/seo (3).png';
import seo4 from '../../media/seo (4).png';
import seo5 from '../../media/seo (5).png';
import seo6 from '../../media/seo (6).png';
import seo7 from '../../media/seo (7).png';
import seo8 from '../../media/seo (8).png';
import seo9 from '../../media/seo (9).png';

const OrbitingCircles = () => {
  const canvasRef = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  
  // Define tools with their images
  const innerTools = [
    { src: seo6, size: 80 },  // Search Console
    { src: seo3, size: 40 },  // Google Analytics
    { src: seo1, size: 40 },  // Bing
    { src: seo7, size: 40 }   // Semrush
  ];

  const outerTools = [
    { src: seo2, size: 50 },  // Ahrefs
    { src: seo4, size: 50 },  // Screaming Frog
    { src: seo5, size: 50 },  // Answer The Public
    { src: seo8, size: 50 },  // Ubersuggest
    { src: seo9, size: 50 }   // Google Trends
  ];

  // Load images first
  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
      });
    };

    const loadAllImages = async () => {
      const images = {};
      const allTools = [...innerTools, ...outerTools];
      
      try {
        await Promise.all(
          allTools.map(async (tool) => {
            images[tool.src] = await loadImage(tool.src);
          })
        );
        setLoadedImages(images);
        setImagesLoaded(true);
      } catch (error) {
        console.error('Error loading images:', error);
      }
    };

    loadAllImages();
  }, []);

  // Drawing effect
  useEffect(() => {
    if (!imagesLoaded) return;

    const canvas = canvasRef.current;
    canvas.width = 500;
    canvas.height = 500;
    const ctx = canvas.getContext('2d');
    let animationFrameId;
    let innerRotation = 0;
    let outerRotation = 0;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const innerRadius = canvas.width / 5;
    const outerRadius = canvas.width / 3;

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw outer circle path
      ctx.beginPath();
      ctx.arc(centerX, centerY, outerRadius, 0, Math.PI * 2);
      ctx.strokeStyle = '#1f1f1f';
      ctx.lineWidth = 1;
      ctx.stroke();

      // Draw inner circle path
      ctx.beginPath();
      ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2);
      ctx.strokeStyle = '#1f1f1f';
      ctx.lineWidth = 1;
      ctx.stroke();

      // Draw "SEO" text in center with vertical gradient
      const gradient = ctx.createLinearGradient(
        centerX,
        centerY - 50,
        centerX,
        centerY + 50
      );
      gradient.addColorStop(0, '#1f1f1f');
      gradient.addColorStop(1, '#666666');

      ctx.font = 'bold 120px Arial';
      ctx.fillStyle = gradient;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('SEO', centerX, centerY);

      // Draw inner circle tools
      innerTools.forEach((tool, i) => {
        const angle = (i * 2 * Math.PI / innerTools.length) + innerRotation;
        const x = centerX + Math.cos(angle) * innerRadius;
        const y = centerY + Math.sin(angle) * innerRadius;
        
        const img = loadedImages[tool.src];
        if (img) {
          ctx.drawImage(
            img,
            x - tool.size/2,
            y - tool.size/2,
            tool.size,
            tool.size
          );
        }
      });

      // Draw outer circle tools
      outerTools.forEach((tool, i) => {
        const angle = (i * 2 * Math.PI / outerTools.length) + outerRotation;
        const x = centerX + Math.cos(angle) * outerRadius;
        const y = centerY + Math.sin(angle) * outerRadius;
        
        const img = loadedImages[tool.src];
        if (img) {
          ctx.drawImage(
            img,
            x - tool.size/2,
            y - tool.size/2,
            tool.size,
            tool.size
          );
        }
      });

      // Update rotations
      innerRotation += 0.001;
      outerRotation -= 0.0005;
      animationFrameId = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [imagesLoaded, loadedImages]);

  return (
    <div className="container-fluid" style={{ backgroundColor: '#ffffff' }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <div className="orbiting-circles-container">
              <canvas
                ref={canvasRef}
                className="orbiting-circles"
                width={500}
                height={500}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrbitingCircles; 